<template>
  <PageWrapDefault
    baseClass="none"
    :hidePageTitle="true"
    :showBack="false"
    :showBreadcrumbs="false"
  >
    <CustomAlert
      v-if="userPendingTutorial"
      class="mt-6"
      title="Onboarding"
      type="info"
      :dismissible="false"
    >
      <p>
        Welcome! Your account has been verified. Follow the steps below to
        continue setting up your account.
      </p>
      <router-link class="font-medium" to="/profile">
        1. Click here to view your profile and make sure the information is
        accurate.
      </router-link>
      <p>
        2. If you have any questions, contact us, or use the chat feature in the
        corner of the screen.
      </p>
    </CustomAlert>
    <div v-if="hasViewedTutorial" class="font-bold md:ml-4 my-10 text-lg">
      Thank you for reviewing the training video and documents. Your account has
      now been activated.
    </div>
    <!-- video -->
    <div
      class="py-4 relative h-auto w-auto"
      :class="{ 'mt-6': !userPendingTutorial }"
    >
      <BillingInquiryVideo />
    </div>
    <!-- Tabs -->
    <div class="tabs pb-2 pt-10 block">
      <el-tabs tab-position="left">
        <el-tab-pane label="Quotes">
          <OnboardingContent context="quotes" />
        </el-tab-pane>
        <el-tab-pane label="Claims">
          <OnboardingContent context="claims" />
        </el-tab-pane>
        <el-tab-pane label="Billing">
          <OnboardingContent context="billing" />
        </el-tab-pane>
        <el-tab-pane label="Policy">
          <OnboardingContent context="policy" />
        </el-tab-pane>
      </el-tabs>
    </div>
    <div
      v-if="userPendingTutorial"
      class="ml-6 p-6 mb-8"
      :class="{
        'bg-green-100': hasViewedTutorial,
        'bg-blue-100': !hasViewedTutorial
      }"
    >
      <h2 class="text-base">Training Acknowledgment</h2>
      <el-switch
        class="mb-2 mt-4"
        @change="updateUserCompletedTutorials"
        :disabled="disableAcknowledgementSwitch"
        v-model="hasViewedTutorial"
        active-color="#13ce66"
        inactive-color="#e8e8e8"
        active-text="I acknowledge that I have thoroughly gone through every section of this tutorial"
      />
    </div>
  </PageWrapDefault>
</template>

<script>
import Vue from "vue";
import PageWrapDefault from "@/views/wrappers/PageWrapDefault.vue";
import OnboardingContent from "@/components/Onboarding/OnboardingContent.vue";
import CustomAlert from "@/components/CustomAlert/CustomAlert.vue";
import BillingInquiryVideo from "@/components/Onboarding/BillingInquiryVideo.vue";
import {
  userMapActions,
  userMapGetters,
  userMapMutations
} from "@/store/modules/user";

export default Vue.extend({
  components: {
    PageWrapDefault,
    OnboardingContent,
    CustomAlert,
    BillingInquiryVideo
  },
  name: "Onboarding",
  mounted: function() {
    this.getUserDetails().then(() => {
      this.SET_EDIT_USER(this.$getCurrentUser._id);
    });
  },
  data() {
    return {
      hasViewedTutorial: false,
      disableAcknowledgementSwitch: false
    };
  },
  methods: {
    ...userMapMutations(["SET_EDIT_USER"]),
    ...userMapActions(["updateUser", "getUser"]),
    async getUserDetails() {
      try {
        await this.getUser(this.$getCurrentUser._id);
      } catch (e) {
        this.error = e.message;
        this.$bugSnagClient.notify(e);
      }
    },
    updateUserCompletedTutorials() {
      this.updateUser({
        id: this.$getCurrentUser._id,
        update: {
          isOnboarding: true,
          hasCompletedTutorial: true,
          status: "completedTutorial"
        }
      })
        .then(() => {
          this.$appNotifySuccess("Tutorial completed successfully");
          this.disableAcknowledgementSwitch = true;
          this.hasViewedTutorial = true;
          this.$router.replace("/login").catch(() => {});
        })
        .catch(error => {
          const errorMessage =
            error.message ||
            `Sorry, we couldn't save your progress. Please try again later.`;
          this.$appNotifyError(errorMessage);
          this.hasViewedTutorial = false;
          this.disableAcknowledgementSwitch = false;
          this.$bugSnagClient.notify(error);
        });
    }
  },
  computed: {
    ...userMapGetters(["getEditingUser"]),
    message() {
      return {
        start:
          "Welcome! Your account has been verified. Follow the steps below to continue setting up your account.",
        link:
          "1. Click here to view your profile and make sure the information is accurate.",
        end:
          "2. If you have any questions, contact us, or use the chat feature in the corner of the screen."
      };
    },
    hasDoneTutorialInThePast() {
      return (
        this.getEditingUser?.status === "completedTutorial" ||
        !!this.getEditingUser?.hasCompletedTutorial
      );
    },
    userPendingTutorial() {
      return (
        this.getEditingUser &&
        this.getEditingUser.status &&
        this.getEditingUser.status === "pendingTutorial"
      );
    }
  },
  watch: {
    hasDoneTutorialInThePast(hasDoneTutorial) {
      if (hasDoneTutorial) {
        this.hasViewedTutorial = true;
        this.disableAcknowledgementSwitch = true;
      }
    }
  }
});
</script>

<style></style>
