<template>
  <section>
    <div
      class="wistia_responsive_padding"
      style="padding:56.25% 0 0 0;position:relative;"
    >
      <div
        class="wistia_responsive_wrapper"
        style="height:100%;left:0;position:absolute;top:0;width:100%;"
      >
        <div
          class="wistia_embed wistia_async_mgy5fxawb7 videoFoam=true"
          style="height:100%;position:relative;width:100%"
        >
          <div
            class="wistia_swatch"
            style="height:100%;left:0;opacity:0;overflow:hidden;position:absolute;top:0;transition:opacity 200ms;width:100%;"
          >
            <img
              src="https://fast.wistia.com/embed/medias/mgy5fxawb7/swatch"
              style="filter:blur(5px);height:100%;object-fit:contain;width:100%;"
              alt=""
              aria-hidden="true"
              onload="this.parentNode.style.opacity=1;"
            />
          </div>
        </div>
      </div>
    </div>
    <!-- <el-switch
      class="my-8"
      @change="updateUserCompletedTutorials"
      :disabled="disableAcknowledgementSwitch"
      v-model="hasViewedTutorial"
      active-color="#13ce66"
      inactive-color="#e8e8e8"
      active-text="I acknowledge that I have thoroughly gone through every section of this tutorial"
    /> -->
  </section>
</template>
<script>
import { userMapActions, userMapGetters } from "@/store/modules/user";
export default {
  name: "BillingInquiryVideo",
  data() {
    return {
      scripts: [
        [
          "wistia-json",
          "https://fast.wistia.com/embed/medias/mgy5fxawb7.jsonp"
        ],
        ["wistia-js", "https://fast.wistia.com/assets/external/E-v1.js"]
      ],
      hasViewedTutorial: false,
      disableAcknowledgementSwitch: false
    };
  },
  beforeMount() {
    for (const script of this.scripts) {
      const s = document.createElement("script");
      const [id, src] = script;
      s.type = "text/javascript";
      s.src = src;
      s.id = id;
      document.head.appendChild(s);
    }
  },
  beforeDestroy() {
    for (const script of this.scripts) {
      const [id] = script;
      const s = document.getElementById(id);
      document.head.removeChild(s);
    }
  },
  computed: {
    ...userMapGetters(["getEditingUser"]),
    hasDoneTutorialInThePast() {
      return !!this.getEditingUser?.hasCompletedTutorial;
    }
  },
  methods: {
    ...userMapActions(["updateUser"]),
    updateUserCompletedTutorials() {
      this.updateUser({
        id: this.$getCurrentUser._id,
        update: {
          isOnboarding: true,
          hasCompletedTutorial: true
        }
      })
        .then(() => {
          this.$appNotifySuccess("Tutorial completed successfully");
          this.disableAcknowledgementSwitch = true;
        })
        .catch(error => {
          this.$appNotifyError(
            `Sorry, we couldn't save your progress. Please try again later.`
          );
          this.hasViewedTutorial = false;
          this.disableAcknowledgementSwitch = false;
          this.$bugSnagClient.notify(error);
        });
    }
  },
  watch: {
    hasDoneTutorialInThePast(hasDoneTutorial) {
      if (hasDoneTutorial) {
        this.hasViewedTutorial = true;
        this.disableAcknowledgementSwitch = true;
      }
    }
  }
};
</script>
